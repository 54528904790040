@import "https://fonts.googleapis.com/css?family=Montserrat:300, 400, 700&display=swap";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 0.7rem;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.section-title {
  font-weight: 300;
  color: black;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
}

.section-title span {
  color: rgba(185, 28, 28, 1);
}

/* Header Section */
.logo {
  width: 70px;
  height: 70px;
  margin-left: 5%;
}

#header {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

#header .header {
  min-height: 9vh;
  width: 100%;
  background-color: rgba(31, 30, 30, 0.24);
  transition: 0.3s ease background-color;
  max-width: inherit;
}

#header .nav-bar {
  width: 100%;
  max-width: 1600px;
}

/* About Section */
.about-img {
  height: 100%;
  width: 100%;
  position: relative;
  border: 10px solid white;
}

.about-img::after {
  content: "";
  position: absolute;
  left: -33px;
  top: 19px;
  height: 98%;
  width: 98%;
  border: 3px solid crimson;
  border-radius: 15px;
  z-index: -1;
}

/* Home Section */
#home {
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 0;
}

#home::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.8;
  z-index: -1;
}

#home h1 {
  color: transparent;
  animation: text_reveal 0.5s ease forwards;
  animation-delay: 1s;
}

#home h2 {
  color: whitesmoke;
  animation: text_reveal 0.5s ease forwards;
  animation-delay: 5s;
}

#home h1:nth-child(1) {
  animation-delay: 1s;
}

#home h1:nth-child(2) {
  animation-delay: 2s;
}

#home h1:nth-child(3) {
  animation: text_reveal_name 0.5s ease forwards;
  animation-delay: 3s;
}

#home h1:nth-child(1) span {
  animation-delay: 0.5s;
}

#home h1:nth-child(2) span {
  animation-delay: 1.5s;
}

#home h1:nth-child(3) span {
  animation-delay: 2.5s;
}

/* Projects Section */
.projects {
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 18%;
}

.projects-header h1 {
  margin-bottom: 5%;
}

.all-projects {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.project-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin: 5% auto;
  overflow: hidden;
  border-radius: 2.5%;
}

.project-info {
  padding: 10%;
  flex-basis: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  color: white;
}

.project-info h1 {
  font-size: 2.2rem;
  font-weight: 500;
}

.project-info h4 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 10px;
}

.project-info p {
  font-size: 1.4rem;
  color: white;
}

.project-img {
  flex-basis: 50%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.project-img:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  opacity: 0.3;
}

.project-img img {
  object-fit: cover;
  transition: 0.3s ease transform;
}

.project-item:hover .project-img img {
  transform: scale(1.1);
  opacity: 0.9;
}

/* Keyframes */
@keyframes text_reveal {
  100% {
    color: white;
  }
}

@keyframes text_reveal_name {
  100% {
    color: rgba(185, 28, 28, 1);
    font-weight: 500;
  }
}

/*************************       TABLET VIEW       ***************************/
/* MEDIA QUERY */
@media only screen and (min-width: 768px) {
  #about .col-left .about-img::after {
    left: -45px;
    top: 19px;
  }

  /* Projects Section */
  .projects {
    padding-top: 12%;
    padding-bottom: 8%;
  }

  .project-item:nth-child(even) {
    flex-direction: row-reverse;
  }

  .project-item {
    flex-direction: row;
    height: 350px;
    margin: 0;
    width: 80%;
    border-radius: 5px;
  }

  .all-projects .project-info {
    height: 100%;
  }

  .all-projects .project-img {
    height: 100%;
  }

  .project-info h1 {
    font-size: 2.4rem;
  }

  .project-info h4 {
    font-size: 1rem;
    margin-top: 20px;
  }

  .project-info p {
    font-size: 1.2rem;
  }

  .project-img {
    height: 350px;
  }
}
/* End Media Query For Tablet */

/**********************       DESKTOP VIEW     *************************/
/* MEDIA QUERY */
@media only screen and (min-width: 1200px) {
  /* Projects Section */
  .projects {
    padding-top: 6%;
    padding-bottom: 4%;
  }

  .project-item {
    width: 60vw;
    border-radius: 5px;
    height: 380px;
  }

  .project-info h1 {
    font-size: 3rem;
  }

  .project-info h4 {
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .project-info p {
    font-size: 1.5rem;
  }
  /* End of Projects section */
}
